
import { defineComponent, ref } from 'vue'
export default defineComponent({
  emits: ['close-modalwarning-visible'],
  props: {
    modalWarning: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  setup(_, context) {
    const checked = ref(false)
    const submitSingleLicense = () => {
      if (checked.value) {
        context.emit('close-modalwarning-visible', {
          modalWarning: false,
          submitContinue: true,
          forceSubmit: false
        })
        checked.value = false
      }
    }
    const cancel = () => {
      context.emit('close-modalwarning-visible', {
        modalWarning: false,
        submitContinue: false,
        forceSubmit: false
      })
      checked.value = false
    }
    return {
      submitSingleLicense,
      cancel,
      checked,
    }
  },
})
