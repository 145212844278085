
import { defineComponent, computed } from "vue";
export default defineComponent({
  emits: ["close", "force-submit"],
  props: {
    batchSubmitVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
    vinNo: {
      type: String,
      required: true
    },
    result: {
      type: String,
      required: true
    },
  },
  setup(props, context) {
    const canSubmit = computed(() => {
      return !props.result.includes('不能为空')
    })

    const handleClose = () => {
      context.emit("close");
    };
    const handleSubmit = () => {
      context.emit("force-submit");
    };
    return {
      canSubmit,
      handleClose,
      handleSubmit
    };
  },
});
