
import { defineComponent, computed } from "vue";
import { exportErrMsg } from '@/API/vl/vlOverview';
import { downloadFromStream } from '@/utils/payment/downloadFile';

export default defineComponent({
  emits: ["close-batch-submit", "force-submit"],
  props: {
    batchSubmitVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
    tableData: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  setup(props, context) {
    const allSuccess = computed(() => {
      if (!props.tableData || props.tableData.length === 0) {
        return true;
      }
      return props.tableData.every((item: any) => item.result === "成功") || props.tableData.every((item: any) => item.reason.indexOf("不能为空") >= 0);
    });
    const columns = [
      {
        title: "车架号",
        dataIndex: "vin",
      },
      {
        title: "提交结果",
        dataIndex: "result",
        width: 80,
      },
      {
        title: "失败原因",
        dataIndex: "reason",
      },
    ];

    const handleClose = () => {
      context.emit("close-batch-submit");
    };
    const handleSubmit = () => {
      context.emit("force-submit");
    };
    const handleExport = async () => {
      const res = await exportErrMsg(props.tableData);
      downloadFromStream(res);
    };
    return {
      columns,
      handleClose,
      handleSubmit,
      handleExport,
      allSuccess
    };
  },
});
